var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "body-layout",
    [
      _c(
        "div",
        {
          staticClass: "actions flex",
          attrs: { slot: "actions" },
          slot: "actions",
        },
        [
          _c(
            "a-button",
            {
              directives: [
                {
                  name: "has",
                  rawName: "v-has",
                  value: "statistics:settlement:dele",
                  expression: "'statistics:settlement:dele'",
                },
              ],
              staticClass: "mr-3",
              attrs: {
                type: "danger",
                disabled: !_vm.selectRows.length,
                icon: "delete",
              },
              on: { click: _vm.batchDel },
            },
            [_vm._v("删除")]
          ),
          _c(
            "a-button",
            {
              directives: [
                {
                  name: "has",
                  rawName: "v-has",
                  value: "statistics:settlement:confirm",
                  expression: "'statistics:settlement:confirm'",
                },
              ],
              staticClass: "mr-3",
              attrs: { type: "primary" },
              on: { click: _vm.confirmRating },
            },
            [_vm._v("确认")]
          ),
          _c(
            "a-dropdown",
            [
              _c(
                "a-menu",
                { attrs: { slot: "overlay" }, slot: "overlay" },
                [
                  _c(
                    "a-menu-item",
                    { key: "1", on: { click: _vm.openTaskList } },
                    [_vm._v(" 系统内任务 ")]
                  ),
                  _c("a-menu-item", { key: "2", on: { click: _vm.add } }, [
                    _vm._v("系统外任务"),
                  ]),
                ],
                1
              ),
              _c(
                "a-button",
                {
                  directives: [
                    {
                      name: "has",
                      rawName: "v-has",
                      value: "settlement:add",
                      expression: "'settlement:add'",
                    },
                  ],
                  attrs: { type: "primary" },
                },
                [_vm._v("添加"), _c("a-icon", { attrs: { type: "down" } })],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { style: _vm.backCss },
        [
          _c(
            "a-spin",
            { attrs: { spinning: _vm.tableLoading } },
            [
              _c(
                "a-dropdown",
                {
                  attrs: { placement: "bottomRight", trigger: ["contextmenu"] },
                  model: {
                    value: _vm.rightVisible,
                    callback: function ($$v) {
                      _vm.rightVisible = $$v
                    },
                    expression: "rightVisible",
                  },
                },
                [
                  _c(
                    "a-menu",
                    { attrs: { slot: "overlay" }, slot: "overlay" },
                    _vm._l(_vm.columns, function (item, index) {
                      return _c(
                        "a-menu-item",
                        { key: item.dataIndex },
                        [
                          _c(
                            "a-checkbox",
                            {
                              on: {
                                change: function ($event) {
                                  return _vm.checkChange(index)
                                },
                              },
                              model: {
                                value: item.checked,
                                callback: function ($$v) {
                                  _vm.$set(item, "checked", $$v)
                                },
                                expression: "item.checked",
                              },
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(item.title) +
                                  "\n            "
                              ),
                            ]
                          ),
                        ],
                        1
                      )
                    }),
                    1
                  ),
                  _c(
                    "vxe-table",
                    {
                      ref: "xTree",
                      attrs: {
                        border: "none",
                        "row-config": {
                          isCurrent: true,
                          isHover: true,
                          keyField: "uniqueKey",
                        },
                        "column-config": { resizable: true },
                        "footer-method": _vm.footerMethod,
                        "tree-config": _vm.treeConfig,
                        "scroll-y": { yt: -1 },
                        "show-overflow": "",
                        resizable: "",
                        "filter-config": {
                          remote: true,
                          iconNone: "iconfont icon-shaixuan icon",
                          iconMatch:
                            "iconfont icon-shaixuan icon filter-product-btn",
                        },
                        data: _vm.tableData,
                      },
                      on: {
                        "checkbox-change": _vm.selectChangeEvent,
                        "checkbox-all": _vm.selectChangeEvent,
                        "cell-click": _vm.cellClickEvent,
                        "toggle-tree-expand": _vm.toggleExpandChangeEvent,
                        "filter-visible": _vm.filterVisible,
                        scroll: _vm.tableScroll,
                        "filter-change": _vm.filterChangeEvent,
                      },
                    },
                    [
                      _c("vxe-column", {
                        attrs: {
                          align: "center",
                          type: "checkbox",
                          fixed: "left",
                          width: "80",
                        },
                      }),
                      _vm._l(
                        _vm.columns.filter((item) => item.checked),
                        function (record) {
                          return _c("vxe-column", {
                            key: record.dataIndex,
                            attrs: {
                              fixed:
                                record.dataIndex == "name"
                                  ? "left"
                                  : record.dataIndex == "totalScore"
                                  ? ""
                                  : "",
                              field: record.dataIndex,
                              title: record.title,
                              minWidth: record.width,
                              "tree-node": record.dataIndex == "name",
                              "show-overflow": "",
                              filters:
                                record.dataIndex == "totalScore" ||
                                record.dataIndex == "evalResult" ||
                                record.dataIndex == "name" ||
                                record.dataIndex == "finishStatus"
                                  ? []
                                  : null,
                            },
                            scopedSlots: _vm._u(
                              [
                                record.dataIndex == "user"
                                  ? {
                                      key: "default",
                                      fn: function ({ row }) {
                                        return [
                                          row.chapterSortAndStageName
                                            ? [
                                                row.editable
                                                  ? _c(
                                                      "a-select",
                                                      {
                                                        staticStyle: {
                                                          margin: "-5px 0",
                                                          width: "150px",
                                                        },
                                                        attrs: {
                                                          showArrow: "",
                                                          placeholder:
                                                            "选择用户",
                                                          "option-filter-prop":
                                                            "label",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            $event.stopPropagation()
                                                          },
                                                          change: function (
                                                            $event
                                                          ) {
                                                            return _vm.listEidt(
                                                              row
                                                            )
                                                          },
                                                        },
                                                        model: {
                                                          value: row.ownerId,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              row,
                                                              "ownerId",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "row.ownerId",
                                                        },
                                                      },
                                                      _vm._l(
                                                        _vm.allUsers,
                                                        function (user) {
                                                          return _c(
                                                            "a-select-option",
                                                            {
                                                              key: user.userId,
                                                              attrs: {
                                                                label:
                                                                  user.userName,
                                                                value:
                                                                  user.userId,
                                                              },
                                                            },
                                                            [
                                                              _c("img", {
                                                                staticStyle: {
                                                                  width: "30px",
                                                                  height:
                                                                    "30px",
                                                                  "border-radius":
                                                                    "50%",
                                                                  "margin-right":
                                                                    "10px",
                                                                },
                                                                attrs: {
                                                                  src: user.avatar,
                                                                  alt: "",
                                                                },
                                                              }),
                                                              _c("span", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    user.userName
                                                                  )
                                                                ),
                                                              ]),
                                                            ]
                                                          )
                                                        }
                                                      ),
                                                      1
                                                    )
                                                  : [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticStyle: {
                                                            display: "flex",
                                                            "align-items":
                                                              "center",
                                                          },
                                                        },
                                                        [
                                                          _c("img", {
                                                            staticStyle: {
                                                              width: "30px",
                                                              height: "30px",
                                                              "border-radius":
                                                                "50%",
                                                              "margin-right":
                                                                "10px",
                                                            },
                                                            attrs: {
                                                              src: row.avatar,
                                                              alt: "",
                                                            },
                                                          }),
                                                          _vm._v(
                                                            "\n                    " +
                                                              _vm._s(
                                                                row.userName
                                                              ) +
                                                              "\n                  "
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                              ]
                                            : row.platformAndCoordinator &&
                                              row.productSortAndName
                                            ? [
                                                row.chapterOrderRange
                                                  ? _c("span", [
                                                      _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            row.chapterOrderRange
                                                          )
                                                        ),
                                                      ]),
                                                      _c(
                                                        "span",
                                                        {
                                                          staticStyle: {
                                                            padding:
                                                              "0 3px 0 3px",
                                                          },
                                                        },
                                                        [_vm._v(" ")]
                                                      ),
                                                      _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            row.calcCount +
                                                              _vm.getUnit(
                                                                row.calcUnit
                                                              )
                                                          )
                                                        ),
                                                      ]),
                                                      _c(
                                                        "span",
                                                        {
                                                          staticStyle: {
                                                            padding:
                                                              "0 3px 0 3px",
                                                          },
                                                        },
                                                        [_vm._v(" ")]
                                                      ),
                                                      row.unitPrice &&
                                                      row.moneyType
                                                        ? _c("span", [
                                                            _vm._v(
                                                              _vm._s(
                                                                row.unitPrice +
                                                                  _vm
                                                                    .moneyTypeMap[
                                                                    row
                                                                      .moneyType
                                                                  ]
                                                              )
                                                            ),
                                                          ])
                                                        : !row.unitPrice &&
                                                          row.moneyType
                                                        ? _c("span", [
                                                            _c(
                                                              "span",
                                                              {
                                                                staticStyle: {
                                                                  color: "red",
                                                                },
                                                              },
                                                              [_vm._v("-")]
                                                            ),
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm
                                                                    .moneyTypeMap[
                                                                    row
                                                                      .moneyType
                                                                  ]
                                                                )
                                                            ),
                                                          ])
                                                        : !row.unitPrice &&
                                                          !row.moneyType
                                                        ? _c(
                                                            "span",
                                                            {
                                                              staticStyle: {
                                                                color: "red",
                                                              },
                                                            },
                                                            [_vm._v("-")]
                                                          )
                                                        : _vm._e(),
                                                      _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            "/" +
                                                              _vm.getUnit(
                                                                row.calcUnit
                                                              )
                                                          )
                                                        ),
                                                      ]),
                                                      _c(
                                                        "span",
                                                        {
                                                          staticStyle: {
                                                            padding:
                                                              "0 3px 0 3px",
                                                          },
                                                        },
                                                        [_vm._v(" ")]
                                                      ),
                                                      _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            row.resultPrice +
                                                              _vm.moneyTypeMap[
                                                                row.moneyType
                                                              ]
                                                          )
                                                        ),
                                                      ]),
                                                    ])
                                                  : _vm._e(),
                                              ]
                                            : row.calcCounts &&
                                              row.calcCounts.length &&
                                              row.name == row.stageName
                                            ? [
                                                _c("span", [
                                                  _vm._v(
                                                    _vm._s(
                                                      row.calcCounts
                                                        .map((item) => {
                                                          return (
                                                            item.calcCount +
                                                            _vm.getUnit(
                                                              item.calcUnit
                                                            )
                                                          )
                                                        })
                                                        .join("  ")
                                                    )
                                                  ),
                                                ]),
                                                row.monies && row.monies.length
                                                  ? _c("span", [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            row.monies
                                                              .map((item) => {
                                                                return (
                                                                  (
                                                                    item.money -
                                                                    0
                                                                  ).toFixed(0) +
                                                                    _vm
                                                                      .moneyTypeMap[
                                                                      item
                                                                        .moneyType
                                                                    ] || ""
                                                                )
                                                              })
                                                              .join("  ")
                                                          )
                                                      ),
                                                    ])
                                                  : _vm._e(),
                                              ]
                                            : row.calcCounts &&
                                              row.calcCounts.length &&
                                              row.platformAndCoordinator
                                            ? [
                                                _c("span", [
                                                  _vm._v(
                                                    _vm._s(
                                                      row.calcCounts
                                                        .map((item) => {
                                                          return (
                                                            item.calcCount +
                                                            _vm.getUnit(
                                                              item.calcUnit
                                                            )
                                                          )
                                                        })
                                                        .join("  ")
                                                    )
                                                  ),
                                                ]),
                                                row.monies && row.monies.length
                                                  ? _c("span", [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            row.monies
                                                              .map((item) => {
                                                                return (
                                                                  item.money +
                                                                    _vm
                                                                      .moneyTypeMap[
                                                                      item
                                                                        .moneyType
                                                                    ] || ""
                                                                )
                                                              })
                                                              .join("  ")
                                                          )
                                                      ),
                                                    ])
                                                  : _vm._e(),
                                              ]
                                            : _vm._e(),
                                        ]
                                      },
                                    }
                                  : record.dataIndex == "money"
                                  ? {
                                      key: "default",
                                      fn: function ({ row }) {
                                        return [
                                          row.edit && !row.hasChild
                                            ? _c("a-input-number", {
                                                on: {
                                                  blur: function ($event) {
                                                    return _vm.saveMoney(row)
                                                  },
                                                },
                                                model: {
                                                  value: row.money,
                                                  callback: function ($$v) {
                                                    _vm.$set(row, "money", $$v)
                                                  },
                                                  expression: "row.money",
                                                },
                                              })
                                            : !row.edit && !row.hasChild
                                            ? [
                                                _c(
                                                  "span",
                                                  {
                                                    on: {
                                                      click: function ($event) {
                                                        $event.stopPropagation()
                                                        return _vm.moneyClick(
                                                          row
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [_vm._v(_vm._s(row.money))]
                                                ),
                                                _c("span", [
                                                  _vm._v(
                                                    _vm._s(
                                                      row.moneyType &&
                                                        _vm.moneyTypeMap[
                                                          row.moneyType
                                                        ]
                                                    )
                                                  ),
                                                ]),
                                              ]
                                            : _vm._e(),
                                        ]
                                      },
                                    }
                                  : record.dataIndex == "settleStatus"
                                  ? {
                                      key: "default",
                                      fn: function ({ row }) {
                                        return [
                                          _c("span", [
                                            _vm._v(
                                              "\n                " +
                                                _vm._s(
                                                  row.settleStatus ==
                                                    "NOT_SETTLE"
                                                    ? ""
                                                    : row.settleStatus ==
                                                      "SYSTEM_SETTLED"
                                                    ? "系统已结算"
                                                    : row.settleStatus ==
                                                      "USER_SETTLED"
                                                    ? "手动已结算"
                                                    : ""
                                                ) +
                                                "\n              "
                                            ),
                                          ]),
                                        ]
                                      },
                                    }
                                  : record.dataIndex == "name"
                                  ? {
                                      key: "default",
                                      fn: function ({ row }) {
                                        return [
                                          _c(
                                            "span",
                                            {
                                              class:
                                                !row.hasChild &&
                                                row.isExtraSettle &&
                                                _vm.checkPermission(
                                                  "settlement:add"
                                                )
                                                  ? "stage-name"
                                                  : "",
                                              on: {
                                                click: function ($event) {
                                                  !row.hasChild &&
                                                  row.isExtraSettle &&
                                                  _vm.checkPermission(
                                                    "settlement:add"
                                                  )
                                                    ? _vm.edit(row)
                                                    : () => {}
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(row.name) +
                                                  _vm._s(
                                                    row.platformAndCoordinator &&
                                                      row.productSortAndName &&
                                                      row.hasChild &&
                                                      row.productTotal
                                                      ? "（" +
                                                          row.productTotal +
                                                          "）"
                                                      : ""
                                                  )
                                              ),
                                            ]
                                          ),
                                        ]
                                      },
                                    }
                                  : record.dataIndex == "completeStatus"
                                  ? {
                                      key: "default",
                                      fn: function ({ row }) {
                                        return [
                                          row.chapterSortAndStageName
                                            ? _c(
                                                "div",
                                                {
                                                  staticStyle: {
                                                    display: "flex",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      $event.stopPropagation()
                                                    },
                                                  },
                                                },
                                                [
                                                  _c("actions-tasks", {
                                                    attrs: {
                                                      propsParams: row,
                                                      isMyTask: true,
                                                      isMyTaskList: true,
                                                      isCalendar: true,
                                                      loadData:
                                                        _vm.getTableData,
                                                    },
                                                  }),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                        ]
                                      },
                                    }
                                  : record.dataIndex == "calcUnits"
                                  ? {
                                      key: "default",
                                      fn: function ({ row }) {
                                        return [
                                          row.chapterSortAndStageName
                                            ? _c("div", [
                                                _vm._v(
                                                  "\n                " +
                                                    _vm._s(
                                                      _vm.getCalcUnits(
                                                        row.calcUnits
                                                      )
                                                    ) +
                                                    "\n              "
                                                ),
                                              ])
                                            : _vm._e(),
                                        ]
                                      },
                                    }
                                  : record.dataIndex == "totalScore"
                                  ? {
                                      key: "default",
                                      fn: function ({ row }) {
                                        return [
                                          row.platformAndCoordinator &&
                                          row.productSortAndName &&
                                          row.productTotal > 50
                                            ? _c(
                                                "div",
                                                {
                                                  staticStyle: {
                                                    width: "200px",
                                                  },
                                                },
                                                [
                                                  _c("a-pagination", {
                                                    attrs: {
                                                      size: "small",
                                                      showLessItems: true,
                                                      defaultPageSize: 50,
                                                      "default-current": 1,
                                                      total:
                                                        row.productTotal - 0,
                                                    },
                                                    on: {
                                                      change: (
                                                        page,
                                                        pageSize
                                                      ) => {
                                                        _vm.treeTablePageChange(
                                                          page,
                                                          pageSize,
                                                          row
                                                        )
                                                      },
                                                    },
                                                  }),
                                                ],
                                                1
                                              )
                                            : row.chapterSortAndStageName
                                            ? _c("div", [
                                                _vm._v(
                                                  "\n                " +
                                                    _vm._s(
                                                      row.totalScore || ""
                                                    ) +
                                                    "\n              "
                                                ),
                                              ])
                                            : _vm._e(),
                                        ]
                                      },
                                    }
                                  : record.dataIndex == "evalResult"
                                  ? {
                                      key: "default",
                                      fn: function ({ row }) {
                                        return [
                                          row.chapterSortAndStageName
                                            ? [
                                                _c(
                                                  "span",
                                                  [
                                                    _vm._l(
                                                      row.evalResult
                                                        .kpiValueErrors,
                                                      function (
                                                        item,
                                                        kpiViewindex
                                                      ) {
                                                        return [
                                                          item.count > 0
                                                            ? _c(
                                                                "a-tag",
                                                                {
                                                                  key: kpiViewindex,
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n                      " +
                                                                      _vm._s(
                                                                        (item.type ==
                                                                        "SOFT"
                                                                          ? "软伤数"
                                                                          : item.type ==
                                                                            "HARD"
                                                                          ? "硬伤数"
                                                                          : item.type ==
                                                                            "ERROR"
                                                                          ? "错翻数"
                                                                          : item.type ==
                                                                            "SUPER_HARD"
                                                                          ? "超级硬伤数"
                                                                          : item.type ==
                                                                            "V1_HARD"
                                                                          ? "1改硬伤数"
                                                                          : item.type ==
                                                                            "V2_HARD"
                                                                          ? "2改硬伤数"
                                                                          : item.type ==
                                                                            "MORE_THAN_V2_HARD"
                                                                          ? "2改以上硬伤数"
                                                                          : item.type ==
                                                                            "SUPER_SOFT"
                                                                          ? "超级软伤"
                                                                          : "") +
                                                                          " : " +
                                                                          item.count
                                                                      ) +
                                                                      "\n                    "
                                                                  ),
                                                                ]
                                                              )
                                                            : _vm._e(),
                                                        ]
                                                      }
                                                    ),
                                                    row.evalResult
                                                      .hasKpiModifies &&
                                                    row.evalResult
                                                      .kpiModifyRatio > 0
                                                      ? _c("a-tag", [
                                                          _vm._v(
                                                            "\n                    修改率 :\n                    "
                                                          ),
                                                          _c(
                                                            "span",
                                                            {
                                                              style:
                                                                row.evalResult
                                                                  .textAmount ||
                                                                !row.isLocal
                                                                  ? ""
                                                                  : "color:red",
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  !row.isLocal
                                                                    ? row
                                                                        .evalResult
                                                                        .kpiModifyRatio +
                                                                        "%"
                                                                    : row
                                                                        .evalResult
                                                                        .textAmount
                                                                    ? row
                                                                        .evalResult
                                                                        .kpiModifyRatio +
                                                                      "%"
                                                                    : "X"
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                        ])
                                                      : _vm._e(),
                                                  ],
                                                  2
                                                ),
                                              ]
                                            : _vm._e(),
                                        ]
                                      },
                                    }
                                  : record.dataIndex == "finishStatus"
                                  ? {
                                      key: "default",
                                      fn: function ({ row }) {
                                        return [
                                          _c("span", [
                                            _vm._v(
                                              "\n                " +
                                                _vm._s(
                                                  row.finishStatus == "ADVANCE"
                                                    ? "提前"
                                                    : row.finishStatus ==
                                                      "ON_TIME"
                                                    ? "按时"
                                                    : row.finishStatus ==
                                                      "DELAY"
                                                    ? "拖稿"
                                                    : ""
                                                ) +
                                                "\n              "
                                            ),
                                          ]),
                                        ]
                                      },
                                    }
                                  : record.dataIndex == "deadline"
                                  ? {
                                      key: "default",
                                      fn: function ({ row }) {
                                        return [
                                          row.editable
                                            ? _c("a-date-picker", {
                                                staticStyle: {
                                                  margin: "-5px 0",
                                                  "min-width": "145px",
                                                },
                                                attrs: {
                                                  format: "YYYY-MM-DD HH:mm",
                                                  valueFormat:
                                                    "YYYY-MM-DD HH:mm",
                                                  "show-time": {
                                                    format: "HH:mm",
                                                  },
                                                  placeholder: "请选择截止日期",
                                                },
                                                on: {
                                                  ok: function ($event) {
                                                    return _vm.listEidt(row)
                                                  },
                                                },
                                                model: {
                                                  value: row.deadline,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      row,
                                                      "deadline",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "row.deadline",
                                                },
                                              })
                                            : [
                                                _vm._v(
                                                  "\n                " +
                                                    _vm._s(row.deadline) +
                                                    "\n              "
                                                ),
                                              ],
                                        ]
                                      },
                                    }
                                  : record.dataIndex == "stageName"
                                  ? {
                                      key: "filter",
                                      fn: function ({ $panel, column }) {
                                        return [
                                          _c(
                                            "a-spin",
                                            {
                                              staticStyle: {
                                                "padding-left": "10px",
                                                "padding-right": "50px",
                                                "min-height": "30px",
                                                "max-height": "200px",
                                                "overflow-y": "auto",
                                              },
                                              attrs: {
                                                spinning: _vm.filterLoading,
                                              },
                                            },
                                            [
                                              _c("a-tree", {
                                                attrs: {
                                                  checkable: "",
                                                  "tree-data": _vm.treeData,
                                                },
                                                on: {
                                                  select: (
                                                    selectedKeys,
                                                    info
                                                  ) => {
                                                    _vm.treeSelect(
                                                      record.dataIndex,
                                                      selectedKeys,
                                                      info
                                                    )
                                                  },
                                                },
                                                model: {
                                                  value:
                                                    _vm.checkedStageNameKeys,
                                                  callback: function ($$v) {
                                                    _vm.checkedStageNameKeys =
                                                      $$v
                                                  },
                                                  expression:
                                                    "checkedStageNameKeys",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ]
                                      },
                                    }
                                  : record.dataIndex == "settlementDate"
                                  ? {
                                      key: "filter",
                                      fn: function ({ $panel, column }) {
                                        return [
                                          _c(
                                            "a-spin",
                                            {
                                              staticStyle: {
                                                "padding-left": "10px",
                                                "padding-right": "50px",
                                                "min-height": "30px",
                                                "max-height": "200px",
                                                "overflow-y": "auto",
                                              },
                                              attrs: {
                                                spinning: _vm.filterLoading,
                                              },
                                            },
                                            [
                                              _c("a-tree", {
                                                attrs: {
                                                  defaultExpandedKeys:
                                                    _vm.defaultOpenKeys,
                                                  checkable: "",
                                                  "tree-data": _vm.treeData,
                                                },
                                                on: {
                                                  expand: _vm.treeExpand,
                                                  select: (
                                                    selectedKeys,
                                                    info
                                                  ) => {
                                                    _vm.treeSelect(
                                                      record.dataIndex,
                                                      selectedKeys,
                                                      info
                                                    )
                                                  },
                                                },
                                                model: {
                                                  value:
                                                    _vm.checkedSettlementDateKeys,
                                                  callback: function ($$v) {
                                                    _vm.checkedSettlementDateKeys =
                                                      $$v
                                                  },
                                                  expression:
                                                    "checkedSettlementDateKeys",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ]
                                      },
                                    }
                                  : null,
                                record.dataIndex == "totalScore"
                                  ? {
                                      key: "filter",
                                      fn: function ({ $panel, column }) {
                                        return [
                                          _c(
                                            "span",
                                            [
                                              _c("a-input-number", {
                                                staticStyle: {
                                                  width: "80px",
                                                  "text-align": "center",
                                                },
                                                attrs: {
                                                  min: 0,
                                                  placeholder: "起始值",
                                                },
                                                model: {
                                                  value: _vm.minTotalScore,
                                                  callback: function ($$v) {
                                                    _vm.minTotalScore = $$v
                                                  },
                                                  expression: "minTotalScore",
                                                },
                                              }),
                                              _vm._v(
                                                "\n                ~\n                "
                                              ),
                                              _c("a-input-number", {
                                                staticStyle: {
                                                  width: "80px",
                                                  "text-align": "center",
                                                },
                                                attrs: {
                                                  min: 0,
                                                  placeholder: "结束值",
                                                },
                                                model: {
                                                  value: _vm.maxTotalScore,
                                                  callback: function ($$v) {
                                                    _vm.maxTotalScore = $$v
                                                  },
                                                  expression: "maxTotalScore",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ]
                                      },
                                    }
                                  : record.dataIndex == "evalResult"
                                  ? {
                                      key: "filter",
                                      fn: function ({ $panel, column }) {
                                        return [
                                          _c(
                                            "a-spin",
                                            {
                                              staticStyle: {
                                                "padding-left": "10px",
                                                "padding-right": "50px",
                                                "min-height": "30px",
                                                "max-height": "200px",
                                                "overflow-y": "auto",
                                              },
                                              attrs: {
                                                spinning: _vm.filterLoading,
                                              },
                                            },
                                            [
                                              _c("a-tree", {
                                                attrs: {
                                                  checkable: "",
                                                  "tree-data": _vm.treeData,
                                                },
                                                on: {
                                                  select: (
                                                    selectedKeys,
                                                    info
                                                  ) => {
                                                    _vm.treeSelect(
                                                      record.dataIndex,
                                                      selectedKeys,
                                                      info
                                                    )
                                                  },
                                                },
                                                model: {
                                                  value:
                                                    _vm.checkedEvalResultKeys,
                                                  callback: function ($$v) {
                                                    _vm.checkedEvalResultKeys =
                                                      $$v
                                                  },
                                                  expression:
                                                    "checkedEvalResultKeys",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ]
                                      },
                                    }
                                  : record.dataIndex == "name"
                                  ? {
                                      key: "filter",
                                      fn: function ({ $panel, column }) {
                                        return [
                                          _c(
                                            "div",
                                            {
                                              staticStyle: { padding: "10px" },
                                            },
                                            [
                                              _c("a-input", {
                                                staticStyle: {
                                                  width: "150px",
                                                  height: "24px",
                                                  "line-height": "24px",
                                                },
                                                attrs: {
                                                  placeholder: "请输入作品名称",
                                                },
                                                on: {
                                                  pressEnter: function (
                                                    $event
                                                  ) {
                                                    return _vm.inputFilter(
                                                      record.dataIndex
                                                    )
                                                  },
                                                },
                                                model: {
                                                  value: _vm.productName,
                                                  callback: function ($$v) {
                                                    _vm.productName = $$v
                                                  },
                                                  expression: "productName",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ]
                                      },
                                    }
                                  : record.dataIndex == "finishStatus"
                                  ? {
                                      key: "filter",
                                      fn: function ({ $panel, column }) {
                                        return [
                                          _c(
                                            "a-spin",
                                            {
                                              staticStyle: {
                                                "padding-left": "10px",
                                                "padding-right": "50px",
                                                "min-height": "30px",
                                                "max-height": "200px",
                                                "overflow-y": "auto",
                                              },
                                              attrs: {
                                                spinning: _vm.filterLoading,
                                              },
                                            },
                                            [
                                              _c("a-tree", {
                                                attrs: {
                                                  checkable: "",
                                                  "tree-data": _vm.treeData,
                                                },
                                                on: {
                                                  select: (
                                                    selectedKeys,
                                                    info
                                                  ) => {
                                                    _vm.treeSelect(
                                                      record.dataIndex,
                                                      selectedKeys,
                                                      info
                                                    )
                                                  },
                                                },
                                                model: {
                                                  value:
                                                    _vm.checkedFinishStatusKeys,
                                                  callback: function ($$v) {
                                                    _vm.checkedFinishStatusKeys =
                                                      $$v
                                                  },
                                                  expression:
                                                    "checkedFinishStatusKeys",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ]
                                      },
                                    }
                                  : null,
                              ],
                              null,
                              true
                            ),
                          })
                        }
                      ),
                    ],
                    2
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "a-modal",
            {
              attrs: {
                title: "请更换人员",
                width: "350px",
                visible: _vm.visible,
              },
              on: { ok: _vm.handleOk, cancel: _vm.handleCancel },
            },
            [
              _c(
                "a-select",
                {
                  staticStyle: { width: "250px" },
                  attrs: {
                    showArrow: "",
                    placeholder: "选择用户",
                    "option-filter-prop": "label",
                  },
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                    },
                  },
                  model: {
                    value: _vm.ownerId,
                    callback: function ($$v) {
                      _vm.ownerId = $$v
                    },
                    expression: "ownerId",
                  },
                },
                _vm._l(_vm.allUsers, function (user) {
                  return _c(
                    "a-select-option",
                    {
                      key: user.userId,
                      attrs: { label: user.userName, value: user.userId },
                    },
                    [
                      _c("img", {
                        staticStyle: {
                          width: "25px",
                          height: "25px",
                          "border-radius": "50%",
                          "margin-right": "10px",
                          position: "relative",
                          top: "-2px",
                        },
                        attrs: { src: user.avatar, alt: "" },
                      }),
                      _c("span", [_vm._v(_vm._s(user.userName))]),
                    ]
                  )
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "a-modal",
            {
              attrs: { title: _vm.title, dialogClass: "translate-modal" },
              on: { cancel: _vm.addHandleCancel },
              model: {
                value: _vm.addVisible,
                callback: function ($$v) {
                  _vm.addVisible = $$v
                },
                expression: "addVisible",
              },
            },
            [
              _c("template", { slot: "footer" }, [
                _c(
                  "div",
                  {
                    staticStyle: {
                      display: "flex",
                      "justify-content": "space-between",
                    },
                  },
                  [
                    _c("span", [
                      _vm.title == "新建结算任务"
                        ? _c("a", { on: { click: _vm.addAgin } }, [
                            _vm._v("再次新建"),
                          ])
                        : _vm._e(),
                    ]),
                    _c(
                      "span",
                      [
                        _c(
                          "a-button",
                          { key: "back", on: { click: _vm.addHandleCancel } },
                          [_vm._v("\n              取消\n            ")]
                        ),
                        _c(
                          "a-button",
                          {
                            key: "submit",
                            attrs: { type: "primary" },
                            on: { click: _vm.addHandleOk },
                          },
                          [_vm._v("\n              确定\n            ")]
                        ),
                      ],
                      1
                    ),
                  ]
                ),
              ]),
              _vm.addVisible
                ? _c(
                    "a-form-model",
                    {
                      ref: "ruleForm",
                      attrs: {
                        model: _vm.formData,
                        rules: _vm.rules,
                        "label-col": _vm.labelCol,
                        "wrapper-col": _vm.wrapperCol,
                      },
                    },
                    [
                      _c(
                        "a-form-model-item",
                        { attrs: { label: "任务名", prop: "taskName" } },
                        [
                          _c("a-input", {
                            attrs: { placeholder: "请输入任务名" },
                            model: {
                              value: _vm.formData.taskName,
                              callback: function ($$v) {
                                _vm.$set(_vm.formData, "taskName", $$v)
                              },
                              expression: "formData.taskName",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "a-form-model-item",
                        { attrs: { label: "平台", prop: "platformName" } },
                        [
                          _c("a-input", {
                            attrs: { placeholder: "请输入平台名称" },
                            model: {
                              value: _vm.formData.platformName,
                              callback: function ($$v) {
                                _vm.$set(_vm.formData, "platformName", $$v)
                              },
                              expression: "formData.platformName",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "a-form-model-item",
                        {
                          attrs: { label: "作品名称", prop: "productionName" },
                        },
                        [
                          _c("a-input", {
                            attrs: { placeholder: "请输入作品名称" },
                            model: {
                              value: _vm.formData.productionName,
                              callback: function ($$v) {
                                _vm.$set(_vm.formData, "productionName", $$v)
                              },
                              expression: "formData.productionName",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "a-form-model-item",
                        { attrs: { label: "章节范围", prop: "chapterRange" } },
                        [
                          _c("a-input", {
                            attrs: { placeholder: "请输入章节范围" },
                            model: {
                              value: _vm.formData.chapterRange,
                              callback: function ($$v) {
                                _vm.$set(_vm.formData, "chapterRange", $$v)
                              },
                              expression: "formData.chapterRange",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "a-form-model-item",
                        { attrs: { label: "结算数量", prop: "settleTotal" } },
                        [
                          _c(
                            "a-input-group",
                            { attrs: { compact: "" } },
                            [
                              _c("a-input-number", {
                                staticStyle: { width: "80%" },
                                attrs: { placeholder: "请输入结算数量" },
                                model: {
                                  value: _vm.formData.settleTotal,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.formData, "settleTotal", $$v)
                                  },
                                  expression: "formData.settleTotal",
                                },
                              }),
                              _c(
                                "a-select",
                                {
                                  staticStyle: { width: "20%" },
                                  attrs: { placeholder: "请输入结算数量" },
                                  model: {
                                    value: _vm.formData.settleUnit,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.formData, "settleUnit", $$v)
                                    },
                                    expression: "formData.settleUnit",
                                  },
                                },
                                _vm._l(
                                  Object.values(_vm.unitPriceTypeList),
                                  function (item) {
                                    return _c(
                                      "a-select-option",
                                      {
                                        key: item.label,
                                        attrs: { value: item.value },
                                      },
                                      [_vm._v(_vm._s(item.label))]
                                    )
                                  }
                                ),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "a-form-model-item",
                        { attrs: { label: "单价", prop: "settlePrice" } },
                        [
                          _c(
                            "a-input-group",
                            { attrs: { compact: "" } },
                            [
                              _c("a-input-number", {
                                staticStyle: { width: "80%" },
                                attrs: { placeholder: "请输入单价" },
                                model: {
                                  value: _vm.formData.settlePrice,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.formData, "settlePrice", $$v)
                                  },
                                  expression: "formData.settlePrice",
                                },
                              }),
                              _c(
                                "a-select",
                                {
                                  staticStyle: { width: "20%" },
                                  attrs: { placeholder: "请选择单位类型" },
                                  model: {
                                    value: _vm.formData.priceUnit,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.formData, "priceUnit", $$v)
                                    },
                                    expression: "formData.priceUnit",
                                  },
                                },
                                _vm._l(
                                  Object.values(_vm.moneyTypeList),
                                  function (item) {
                                    return _c(
                                      "a-select-option",
                                      {
                                        key: item.label,
                                        attrs: { value: item.value },
                                      },
                                      [_vm._v(_vm._s(item.label))]
                                    )
                                  }
                                ),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "a-form-model-item",
                        { attrs: { label: "结算月", prop: "settleMonth" } },
                        [
                          _c("a-month-picker", {
                            attrs: {
                              format: "YYYY-MM",
                              disabled: true,
                              "show-time": { format: "YYYY-MM" },
                              valueFormat: "YYYY-MM",
                              placeholder: "请选择结算月",
                            },
                            model: {
                              value: _vm.formData.settleMonth,
                              callback: function ($$v) {
                                _vm.$set(_vm.formData, "settleMonth", $$v)
                              },
                              expression: "formData.settleMonth",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "a-form-model-item",
                        { attrs: { label: "备注", prop: "description" } },
                        [
                          _c("a-input", {
                            attrs: { placeholder: "请输入备注" },
                            model: {
                              value: _vm.formData.description,
                              callback: function ($$v) {
                                _vm.$set(_vm.formData, "description", $$v)
                              },
                              expression: "formData.description",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            2
          ),
        ],
        1
      ),
      _c("setDetailedListTable", {
        ref: "setDetailedListTable",
        attrs: {
          userName: _vm.userName,
          departId: _vm.departId,
          settleId: _vm.settleId,
        },
        on: {
          changeSettleId: _vm.changeSettleId,
          getTableData: _vm.getTableData,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }